// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import BlockParser from '../block-parser'
import '../block-parser/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// // ----------------------------------------------------------------------------
// // --------------------------------------------------------------------- Images
// // ----------------------------------------------------------------------------
// export const pageQuery = graphql`
//   query MagPageQuery($pathAuto: String) {
//     thisPage: allFile(filter: { fields: { pathAuto: { eq: $pathAuto } } }) {
//       edges {
//         node {
//           fields {
//             pathAuto
//             publishedUnixTimestampAuto
//             publishedLongHumandateAuto
//             lastModifiedHumandateAuto
//             draftAuto
//             breadcrumbsAuto {
//               title
//               slug
//             }
//           }
//           childResources {
//             title
//             coverImage: coverImagePath {
//               childImageSharp {
//                 gatsbyImageData(
//                   width: 900
//                   placeholder: TRACED_SVG
//                   formats: [AUTO, WEBP, AVIF]
//                 )
//               }
//             }
//             contentBlocks {
//               type
//               content
//             }
//           }
//         }
//       }
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ data, pageContext, ...props }) => {
  // const {
  //   thisPage: {
  //     edges: [{ node: n }],
  //   },
  //   node = flattenGatsbyNode(n),
  // } = data

  // const {
  //   title,
  //   contentBlocks,
  //   pathAuto,
  //   publishedLongHumandateAuto,
  //   breadcrumbsAuto,
  // } = node

  // const pageSchema = {
  //   pageTitle: title,
  //   slug: pathAuto.substring(1),
  //   abstract: title,
  //   breadcrumbs: breadcrumbsAuto,
  // }

  return (
    <StandardPageWrapper
      className="article-page"
      pageSchema={pageSchema}
      {...props}
    >
      <Row justify="space-between">
        <Col xs={24} sm={24} md={17} lg={17} xl={17} xxl={19}>
          <h1>Title</h1>
          {/*<h1>{title}</h1>
          <p className="date">Published on {publishedLongHumandateAuto}</p>
          {map(contentBlocks, (block) => (
            <BlockParser block={block} />
          ))}*/}
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={5}>
          <p>Etc...</p>
        </Col>
      </Row>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
