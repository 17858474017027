// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classnames from 'classnames'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Gallery from 'react-photo-gallery'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const imageRenderer = ({ index, left, top, key, photo }) => (
  <GatsbyImage
    style={{ height: photo.height, width: photo.width, margin: 2 }}
    image={getImage(photo.data)}
  />
)

/** GalleryX */
const GalleryX = ({
  images,
  targetRowHeight = 330,
  forceRowHeight = false,
  style = {},
  className,
  mediaState: { currentWidth },
}) => {
  const rowHeight =
    forceRowHeight === true
      ? targetRowHeight
      : currentWidth === 'w-xxl'
      ? 600
      : currentWidth === 'w-xl'
      ? 450
      : targetRowHeight

  return (
    <div className={classnames('gallery', className)} style={style}>
      <Gallery
        photos={images}
        renderImage={imageRenderer}
        direction="row"
        targetRowHeight={rowHeight}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // (dispatch) => ({,
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedGalleryX = compose(
  withState // Add state
)(GalleryX)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedGalleryX
